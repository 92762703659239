<template>
  <div class="container-fluid h-100 py-3" id="rfqDetailsPage">
    <div class="d-flex flex-column h-100 justify-content-start">
      <div class="text-center">
        <h4 class="mb-0">Common Area Info</h4>
        <h5 class="text-center">Please fill in below:</h5>
      </div>
      <div class="card form-card" id="rfqDetailForm">
        <div class="card-body form-card-body">
          <div class="form-group text-center mb-2">
            <label for="accessControl"
              >Do you currently have access control?
            </label>
            <div class="d-flex align-items-center flex-wrap w-100">
              <div class="p-2 w-50">
                <button
                  class="btn btn-lg btn-secondary w-100"
                  :class="{ active: Boolean(vv.accessControl.$model) }"
                  @click="setAccessControl(true)"
                >
                  Yes
                  <BIconCheckCircleFill
                    v-show="Boolean(vv.accessControl.$model)"
                    :key="vv.accessControl.$model"
                    class="text-success align-self-center"
                  />
                </button>
              </div>
              <div class="p-2 w-50">
                <button
                  class="btn btn-lg btn-secondary w-100"
                  :class="{
                    active:
                      data.formStarted && !Boolean(vv.accessControl.$model),
                  }"
                  @click="setAccessControl(false)"
                >
                  No
                  <BIconCheckCircleFill
                    v-show="
                      data.formStarted && !Boolean(vv.accessControl.$model)
                    "
                    :key="vv.accessControl.$model"
                    class="text-success align-self-center"
                  />
                </button>
              </div>
            </div>
          </div>

          <div v-show="vv.accessControl.$model" :key="vv.accessControl.$model">
            <div class="divider"></div>

            <div class="form-group">
              <label for="accessControlBrand">Please enter the brand:</label>
              <input
                type="text"
                name="accessControlBrand"
                id="accessControlBrand"
                ref="accessControlBrandRef"
                :class="{
                  'is-invalid':
                    vv.accessControlBrand.$dirty &&
                    vv.accessControlBrand.$invalid,
                }"
                class="form-control"
                v-model="vv.accessControlBrand.$model"
              />
              <div class="invalid-feedback">
                {{ vv?.accessControlBrand?.$errors[0]?.$message }}
              </div>
            </div>

            <div class="divider"></div>

            <div class="form-group">
              <label for="accessControlAccessPoints"
                >Number of access points:</label
              >
              <input
                type="number"
                name="accessControlAccessPoints"
                id="accessControlAccessPoints"
                :class="{
                  'is-invalid':
                    vv.accessControlAccessPoints.$dirty &&
                    vv.accessControlAccessPoints.$invalid,
                }"
                class="form-control"
                v-model="vv.accessControlAccessPoints.$model"
              />
              <div class="invalid-feedback">
                {{ vv?.accessControlAccessPoints?.$errors[0]?.$message }}
              </div>
            </div>
          </div>

          <div class="divider"></div>

          <div class="form-group mb-4">
            <label for="comments">Other Notes / Comments:</label>
            <textarea
              name="comments"
              id="comments"
              ref="commentsRef"
              rows="4"
              :class="{
                'is-invalid': vv.comments.$dirty && vv.comments.$invalid,
              }"
              class="form-control"
              v-model="vv.comments.$model"
            />
            <div class="invalid-feedback">
              {{ vv?.comments?.$errors[0]?.$message }}
            </div>
          </div>

          <div class="d-flex justify-content-center">
            <button
              class="btn control-btn px-5 my-3"
              id="submitBtn"
              @click="onSubmit"
              :disabled="isSubmitBtnDisabled"
            >
              <div class="d-flex align-items-center justify-content-center">
                <div class="p-2">Submit</div>
                <BIconCaretRightFill />
              </div>
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { useRouter } from "vue-router";
import { required, integer, minValue } from "@vuelidate/validators";
import { reactive, toRef, computed, ref, inject } from "vue";
import api from "@/resources/axios";

export default {
  name: "FormDoorDetails",
  setup() {
    const store = inject("store");
    const router = useRouter();
    const axios = api;

    const data = reactive({
      formStarted: false,
    });

    // refs
    const accessControlBrandRef = ref(null);
    const commentsRef = ref(null);

    // form section
    const fform = reactive({
      accessControl: "",
      accessControlBrand: "",
      accessControlAccessPoints: "",
      comments: "",
    });

    const rules = {
      accessControl: { required },
      accessControlBrand: { required },
      accessControlAccessPoints: { required, integer, minValue: minValue(1) },
      comments: {},
    };

    const vv = useVuelidate(rules, {
      accessControl: toRef(fform, "accessControl"),
      accessControlBrand: toRef(fform, "accessControlBrand"),
      accessControlAccessPoints: toRef(fform, "accessControlAccessPoints"),
      comments: toRef(fform, "comments"),
    });

    const setAccessControl = (value) => {
      data.formStarted = true;
      fform.accessControl = value;

      if (fform.accessControl) {
        // focus AC brand if yes

        // have to set timeout for this to work for some reason? (in FF at least..)
        window.setTimeout(function () {
          accessControlBrandRef.value.focus();
        }, 0);
      } else {
        // focus comments box if no
        window.setTimeout(function () {
          commentsRef.value.focus();
        }, 0);

        // reset unused form data
      }
    };

    const isSubmitBtnDisabled = computed(() => {
      // disable or enable the submit button

      if (data.formStarted && !vv.value.accessControl.$model) {
        // No access control
        return false;
      } else {
        // check for access control
        return false;
      }
    });

    const onSubmit = () => {
      vv.value.$touch();

      // if (vv.value.$invalid) return;

      store.setRfqDetails(fform);
      var rfq = store.getRfqObj();
      rfq.completed = true;

      store.setLoading(true);
      axios
        .put("Rfq/" + rfq.rfqId, rfq)
        .then(() => {
          store.completeApp();
          store.toast.success("RFQ Details Added Successfully.", {
            position: "top-right",
            timeout: 4000,
          });
          router.push({ path: "success" });
        })
        .catch((error) => {
          store.toast.error("Error updating RFQ details.");
          console.log("Error", error);
        })
        .then(() => {
          store.setLoading(false);
        });
    };

    return {
      vv,
      onSubmit,
      setAccessControl,
      isSubmitBtnDisabled,
      data,
      accessControlBrandRef,
      commentsRef,
    };
  },
};
</script>

<style scoped>
label {
  color: #191b1d;
}

.active {
  box-shadow: none !important;
  border: 3px solid #28a745 !important;
}
</style>